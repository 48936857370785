.checkpoint-question {
  @media (min-width: 768px){
    width: 60%;
    padding: 0 10%;
    padding-top: 50px;
  }
  @media (min-width: 1440px){

  }

  .checkpoint-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (min-width: 768px){

    }
    @media (min-width: 768px) and (min-height: 900px){

    }
    @media (min-width: 1440px) and (min-height: 900px){

    }
    .checkpoint-category {
      font-family: $font-bold;
      font-size: 1.8rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      @media (min-width: 768px){
        font-size: vh(18px);
      }
      @media (min-width: 768px) and (min-height: 900px){
        font-size: vw(18px);
      }
      @media (min-width: 1440px) and (min-height: 900px){
        font-size: 1.8rem;
      }
    }
    .checkpoint-number {
      font-family: $font-bold;
      font-size: 1.6rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      background: #fff;
      color: $dark_blue;
      display: inline-flex;
      align-items: center;
      height: 27px;
      padding: 0 13px 0 0;
      transform: matrix(1, 0.03, -0.04, 1, 0, 0);
      @media (min-width: 768px){
        font-size: vh(16px);

      }
      @media (min-width: 768px) and (min-height: 900px){
        font-size: vw(16px);

      }
      @media (min-width: 1440px) and (min-height: 900px){

      }
      span {
        &:first-of-type {
          display: inline-flex;
          align-items: center;
          padding: 0 13px;
          background: $light_blue;
          color: #fff;
          height: 27px;
          position: relative;
          top: -4.5px;
          left: -3px;
          margin-right: 0.2em;
          transform: matrix(1, -0.06, 0.05, 1, 0, 0);
          @media (min-width: 768px){

          }
          @media (min-width: 1440px){

          }
        }

      }
    }
  }

  .question {
    .question-title {
      font-family: $font-bold;
      font-size: 3rem;
      line-height: 1.1;
      @media (min-width: 768px){
        font-size: vh(48px);
      }
      @media (min-width: 768px) and (min-height: 900px){
        font-size: 4.8rem;
      }
    }

    .question-text {
      margin-bottom: 30px;
      @media (min-width: 768px){
        margin-bottom: vh(30px);
      }
      @media (min-width: 768px) and (min-height: 900px){
        margin-bottom: 30px;
      }
    }


    .question-extra {
      margin-bottom: 30px;
      @media (min-width: 768px){
        margin-bottom: vh(30px);
      }
      @media (min-width: 768px) and (min-height: 900px){
        margin-bottom: 30px;
      }

      .button {
        &.active {
          svg {
            .line1 {
              display: none;
            }
          }
        }
      }


      .question-extra-text {
        display: none;
        &.active {
          display: block;
        }
      }
    }

    .options-label {
      font-family: $font-bold;
      font-size: 1.8rem;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 30px;
      @media (min-width: 768px){
        font-size: vh(18px);
        margin-bottom: vh(30px);

      }
      @media (min-width: 768px) and (min-height: 900px){
        font-size: 1.8rem;
        margin-bottom: 30px;
      }
    }


  }


  .options {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .option {
      cursor: pointer;
      display: inline-block;
      width: 140px;
      margin-right: 30px;
      margin-bottom: 30px;
      @media (min-width: 768px){
        margin-right: vw(30px);
        margin-bottom: vw(30px);
      }
      @media (min-width: 768px) and (min-height: 900px){
        margin-right: 30px;
        margin-bottom: 30px;
      }
      &.active {
        &.red {
          background: $red;
          color: #fff;
        }

        &.amber {
          background: $amber;
          color: #fff;
        }

        &.green {
          background: $green;
          color: #fff;
        }
      }
    }
  }

  .checkpoint-actions {
    background: #fff;
    border-radius: 20px;
    display: inline-flex;
    width: auto;

    .button {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        flex-shrink: 0;
        margin-left: 8px;
      }
    }
    .button-explain {
      background: $light_blue;
      color: #fff;
    }
  }
}

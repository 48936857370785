.template-assessments-list {


  .template-columns {
    width: 100%;
    @media (min-width: 768px){
      display: flex;
    }
    @media (min-width: 1440px){

    }


    .template-column {
      &-30 {
        @media (min-width: 768px){
          width: 35%;
          position: relative;
          right: vw(-100px);
        }
        @media (min-width: 1440px){
          right: -100px;
        }
      }
      &-70 {
        @media (min-width: 768px){
          width: 70%;

        }
        @media (min-width: 1440px){

        }
      }
    }
  }
}
